import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFetchDocument } from "../../hooks/useFetchDocument";
import {
  ViewContainer,
  ContentSection,
  SectionTitle,
  SectionContent,
} from "./styles";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import PresetContainer from "../../components/PresetContainer";
import { useDeleteDocument } from "../../hooks/useDeleteDocument";
import { showMessage } from "../../components/ShowMessage";

const enviroment = process.env.REACT_APP_ENVIROMENT;

const AdminTheater = () => {
  const location = useLocation();
  const { id } = location.state || {};
  const { document, loading, error } = useFetchDocument(enviroment === "prod" ? "teatros" : "teatros-test", id);
  const { deleteDocument, response } = useDeleteDocument(enviroment === "prod" ? "teatros" : "teatros-test");
  const navigate = useNavigate();
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    if (response.loading === false && !response.error) {
      showMessage("success", "Peça removida com sucesso", () =>
        navigate("/admin-schedule")
      );
    }
  }, [response, navigate]);

  const handleDelete = async (id) => {
    await deleteDocument(id);
    setConfirmDelete(false);
  };

  return (
    <PresetContainer>
      {loading && (
        <>
          <Loading />
        </>
      )}
      {document && document.titulo && !loading && !error && (
        <>
          <ViewContainer>
            <ContentSection>
              <SectionTitle>Título:</SectionTitle>
              <SectionContent>{document && document.titulo}</SectionContent>
            </ContentSection>

            <ContentSection>
              <SectionTitle>Subtítulo:</SectionTitle>
              <SectionContent>{document && document.subtitulo}</SectionContent>
            </ContentSection>

            <ContentSection>
              <SectionTitle>Data:</SectionTitle>
              <SectionContent>{document && document.data}</SectionContent>
            </ContentSection>

            <ContentSection>
              <SectionTitle>Horário:</SectionTitle>
              <SectionContent>{document && document.horario}</SectionContent>
            </ContentSection>

            <ContentSection>
              <SectionTitle>Imagem:</SectionTitle>
              <SectionContent>
                <img
                  src={document && document.imagem}
                  alt={document && document.titulo}
                  width="200"
                />
              </SectionContent>
            </ContentSection>

            <ContentSection>
              <SectionTitle>Sinopse:</SectionTitle>
              <SectionContent>{document && document.sinopse}</SectionContent>
            </ContentSection>

            <ContentSection>
              <SectionTitle>Local:</SectionTitle>
              <SectionContent>{document && document.local}</SectionContent>
            </ContentSection>

            <ContentSection>
              <SectionTitle>Elenco:</SectionTitle>
              <SectionContent>{document && document.elenco}</SectionContent>
            </ContentSection>

            <ContentSection>
              <SectionTitle>Produção:</SectionTitle>
              <SectionContent>{document && document.producao}</SectionContent>
            </ContentSection>

            <ContentSection>
              <SectionTitle>Informações Adicionais:</SectionTitle>
              <SectionContent>
                {document && document.infoAdicional}
              </SectionContent>
            </ContentSection>

            <ContentSection>
              <SectionTitle>Classificação:</SectionTitle>
              <SectionContent>
                {document && document.classificacao}
              </SectionContent>
            </ContentSection>

            <ContentSection>
              <SectionTitle>Categoria:</SectionTitle>
              <SectionContent>{document && document.categoria}</SectionContent>
            </ContentSection>

            <ContentSection>
              <SectionTitle>Cidade:</SectionTitle>
              <SectionContent>{document && document.cidade}</SectionContent>
            </ContentSection>

            <ContentSection>
              <SectionTitle>Direção:</SectionTitle>
              <SectionContent>{document && document.direcao}</SectionContent>
            </ContentSection>

            <ContentSection>
              <Button
                type="secundary"
                title={document && document.textoBotao}
                handleClick={() =>
                  window.open(document && document.linkBotao, "_blank")
                }
              />
            </ContentSection>

            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "2em",
                marginBottom: 20,
              }}
            >
              <Button
                type="primary"
                title="Voltar"
                handleClick={() => navigate(-1)}
              />

              <Button
                type="primary"
                title="Editar"
                handleClick={() =>
                  navigate("/admin-edit-theater", { state: { id: id } })
                }
              />
            </div>
            <ContentSection>
              {!confirmDelete && (
                <Button
                  type="warning"
                  title="Excluir Peça"
                  loading={response.loading}
                  handleClick={() => setConfirmDelete(true)}
                />
              )}
              {confirmDelete && (
                <div>
                  <p style={{ marginBottom: 4 }}>
                    Tem certeza que deseja excluir?
                  </p>
                  <div style={{ display: "flex", gap: 10 }}>
                    {" "}
                    <Button
                      type="secundary"
                      title="Não"
                      handleClick={() => setConfirmDelete(false)}
                    />
                    <Button
                      type="warning"
                      title="Sim"
                      handleClick={() => handleDelete(id)}
                    />
                  </div>
                </div>
              )}
            </ContentSection>
          </ViewContainer>
        </>
      )}
    </PresetContainer>
  );
};

export default AdminTheater;
