import React, { useEffect, useState } from "react";
import PresetContainer from "../../components/PresetContainer";
import { ViewContainer, ContentSection, SectionContent } from "./styles"; // Reutilizando os estilos de AdminTheater
import Input from "../../components/Input";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { useInsertDocument } from "../../hooks/useInsertDocument";
import { showMessage } from "../../components/ShowMessage";

const enviroment = process.env.REACT_APP_ENVIROMENT;

const AdminAddTheater = () => {
  const [titulo, setTitulo] = useState("");
  const [imagem, setImagem] = useState("");
  const [subtitulo, setSubtitulo] = useState("");
  const [sinopse, setSinopse] = useState("");
  const [data, setData] = useState("");
  const [horario, setHorario] = useState("");
  const [local, setLocal] = useState("");
  const [elenco, setElenco] = useState("");
  const [producao, setProducao] = useState("");
  const [infoAdicional, setInfoAdicional] = useState("");
  const [textoBotao, setTextoBotao] = useState("Adquirir Ingressos");
  const [linkBotao, setLinkBotao] = useState("");
  const [direcao, setDirecao] = useState("");
  const [classificacao, setClassificacao] = useState("");
  const [categoria, setCategoria] = useState("");
  const [cidade, setCidade] = useState("");

  const [emBreve, setEmBreve] = useState(false);

  const navigate = useNavigate();
  const { insertDocument, response } = useInsertDocument(enviroment === "prod" ? "teatros" : "teatros-test");
  useEffect(() => {
    if (response.loading === false && !response.error) {
      showMessage("success", "Peça adicionada com sucesso", () => {
        if (categoria === "1") {
          return navigate("/admin-schedule");
        } else if (categoria === "2") {
          return navigate("/admin-school");
        } else if (categoria === "3") {
          return navigate("/admin-course");
        }
      });
    }
  }, [categoria, response, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      (!titulo ||
        !imagem ||
        !sinopse ||
        !data ||
        !horario ||
        !local ||
        !elenco ||
        !textoBotao ||
        !linkBotao ||
        !direcao ||
        !classificacao ||
        !categoria ||
        !cidade) &&
      !emBreve
    ) {
      showMessage("error", "Campos obrigatórios não preenchidos");
      return;
    }

    if (!titulo || !cidade || !categoria || !imagem) {
      showMessage("error", "Campos obrigatórios não preenchidos");
      return;
    }

    insertDocument(
      {
        titulo,
        subtitulo,
        sinopse,
        data,
        horario,
        local,
        elenco,
        producao,
        infoAdicional,
        textoBotao,
        linkBotao,
        direcao,
        classificacao,
        categoria,
        cidade,
      },
      imagem
    );
  };

  return (
    <PresetContainer>
      <ViewContainer as="form" onSubmit={handleSubmit}>
        <ContentSection>
          <SectionContent>
            <Input
              label={{ text: `Título*`, vertical: true }}
              type="text"
              placeholder="Ex: Harry Potter"
              value={titulo}
              name="titulo"
              onChange={(e) => setTitulo(e)}
            />
          </SectionContent>
        </ContentSection>
        <ContentSection>
          <SectionContent>
            <Input
              label={{ text: "Subtítulo", vertical: true }}
              type="text"
              placeholder="Ex: E a pedra filosofal"
              value={subtitulo}
              name="subtitulo"
              onChange={(e) => setSubtitulo(e)}
            />
          </SectionContent>
        </ContentSection>
        <ContentSection>
          <SectionContent>
            <Input
              label={{ text: "Data(s)*", vertical: true }}
              type="text"
              placeholder="Ex: Dia 1 de abril - Sábado (e Dia 2 de abril - Domingo)"
              value={data}
              name="data"
              onChange={(e) => setData(e)}
            />
          </SectionContent>
        </ContentSection>
        <ContentSection>
          <SectionContent>
            <Input
              label={{
                text: `Horário(s)${emBreve ? "" : "*"}`,
                vertical: true,
              }}
              type="text"
              placeholder="Ex: 20:00 (e 19:00)"
              value={horario}
              name="horario"
              onChange={(e) => setHorario(e)}
            />
          </SectionContent>
        </ContentSection>
        <ContentSection>
          <SectionContent>
            <Input
              label={{ text: "Produção", vertical: true }}
              type="text"
              placeholder="Ex: Alvo´s Dumbledore"
              value={producao}
              name="producao"
              onChange={(e) => setProducao(e)}
            />
          </SectionContent>
        </ContentSection>
        <ContentSection>
          <SectionContent>
            <Input
              label={{ text: "Informações Adicionais", vertical: true }}
              type="text"
              placeholder="Ex: Expectro Patrono"
              value={infoAdicional}
              name="infoAdicional"
              onChange={(e) => setInfoAdicional(e)}
            />
          </SectionContent>
        </ContentSection>
        <ContentSection>
          <SectionContent>
            <Input
              label={{
                text: `Classificação Indicativa${emBreve ? "" : "*"}`,
                vertical: true,
              }}
              type="text"
              placeholder="Ex: Maiores de 14 anos"
              value={classificacao}
              name="classificacao"
              onChange={(e) => setClassificacao(e)}
            />
          </SectionContent>
        </ContentSection>
        <ContentSection>
          <SectionContent>
            <Input
              label={{
                text: `Texto do Botão${emBreve ? "" : "*"}`,
                vertical: true,
              }}
              type="text"
              placeholder="Ex: Adquirir Ingressos"
              value={textoBotao}
              name="textoBotao"
              onChange={(e) => setTextoBotao(e)}
            />
          </SectionContent>
        </ContentSection>
        <ContentSection>
          <SectionContent>
            <Input
              label={{
                text: `Link do Botão${emBreve ? "" : "*"}`,
                vertical: true,
              }}
              type="text"
              placeholder="Ex: https://google.com.br"
              value={linkBotao}
              name="linkBotao"
              onChange={(e) => setLinkBotao(e)}
            />
          </SectionContent>
        </ContentSection>
        <ContentSection>
          <SectionContent>
            <Input
              label={{ text: "Imagem*", vertical: true }}
              type="file"
              name="imagem"
              onChange={(e) => setImagem(e)}
            />
            {imagem && (
              <img
                src={URL.createObjectURL(imagem)}
                alt={titulo}
                width="200"
                style={{ marginTop: "1rem" }}
              />
            )}
          </SectionContent>
        </ContentSection>
        <ContentSection>
          <SectionContent>
            <Input
              label={{ text: `Sinopse${emBreve ? "" : "*"}`, vertical: true }}
              type="textarea"
              placeholder="Ex: Era uma vez..."
              value={sinopse}
              name="sinopse"
              onChange={(e) => setSinopse(e)}
            />
          </SectionContent>
        </ContentSection>
        <ContentSection>
          <SectionContent>
            <Input
              label={{ text: `Local${emBreve ? "" : "*"}`, vertical: true }}
              type="text"
              placeholder="Ex: Shopping Tal (Rua Tal, 13)."
              value={local}
              name="local"
              onChange={(e) => setLocal(e)}
            />
          </SectionContent>
        </ContentSection>
        <ContentSection>
          <SectionContent>
            <Input
              label={{
                text: `Direção e Roteiro${emBreve ? "" : "*"}`,
                vertical: true,
              }}
              type="text"
              placeholder="Ex: Severo Snape"
              value={direcao}
              name="direcao"
              onChange={(e) => setDirecao(e)}
            />
          </SectionContent>
        </ContentSection>
        <ContentSection>
          <SectionContent>
            <Input
              label={{ text: `Elenco${emBreve ? "" : "*"}`, vertical: true }}
              type="textarea"
              placeholder="Ex: Harry Potter, Roney Wesley"
              value={elenco}
              name="elenco"
              onChange={(e) => setElenco(e)}
            />
          </SectionContent>
        </ContentSection>
        <ContentSection>
          <SectionContent>
            <Input
              label={{ text: "Categoria da Peça*", vertical: true }}
              type="select"
              options={[
                { value: 1, name: "Programação Padrão" },
                { value: 2, name: "Escolas" },
              ]}
              value={categoria}
              name="categoria"
              onChange={(e) => setCategoria(e)}
              required={true}
            />
          </SectionContent>
        </ContentSection>
        <ContentSection>
          <SectionContent>
            <Input
              label={{ text: "Cidade*", vertical: true }}
              type="select"
              options={[
                { value: "São Lourenço do Sul", name: "São Lourenço do Sul" },
                { value: "Porto Alegre", name: "Porto Alegre" },
                { value: "Cachoeirinha", name: "Cachoeirinha" },
              ]}
              value={cidade}
              name="cidade"
              onChange={(e) => setCidade(e)}
              required={true}
            />
          </SectionContent>
        </ContentSection>
        <Input
          label={{ text: "Em Breve", vertical: true }}
          type="checkbox"
          value={emBreve}
          name="emBreve"
          onChange={(e) => setEmBreve(!emBreve)}
          required={true}
        />
        <ContentSection
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            type="secundary"
            title="Cancelar"
            handleClick={() => navigate("/admin-schedule")}
          />
          <Button
            type="primary"
            title="Criar"
            loading={response.loading}
            handleClick={handleSubmit}
          />
        </ContentSection>
      </ViewContainer>
    </PresetContainer>
  );
};

export default AdminAddTheater;
