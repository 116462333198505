import { useState, useEffect, useReducer } from "react";
import { db, storage } from "../firebase/config";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

const bucketName = process.env.REACT_APP_BUCKET_NAME;

const enviroment = process.env.REACT_APP_ENVIROMENT;

const initialState = {
  loading: null,
  error: null,
};

const insertReducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return { loading: true, error: null };
    case "ERROR":
      return { loading: false, error: action.payload };
    case "INSERTED_DOC":
      return { loading: false, error: null };
    default:
      return state;
  }
};

export const useInsertDocument = (docCollection) => {
  const [response, dispatch] = useReducer(insertReducer, initialState);

  // deal with memory leak
  const [cancelled, setCancelled] = useState(false);

  const checkCancelBeforeDispatch = (action) => {
    if (enviroment === "prod" ? !cancelled : cancelled) {
      dispatch(action);
    }
  };

  const insertDocument = async (document, file) => {
    checkCancelBeforeDispatch({
      type: "LOADING",
    });
    try {
      const newDocument = { ...document, createdAt: Timestamp.now() };

      let fileUrl = null;
      if (file) {
        const fileRef = ref(storage, `${bucketName}/${file.name}`);
        await uploadBytes(fileRef, file);
        fileUrl = await getDownloadURL(fileRef);
      }

      // Adicione a URL do arquivo ao documento
      if (fileUrl) {
        newDocument.imagem = fileUrl;
      }

      const insertDocument = await addDoc(
        collection(db, docCollection),
        newDocument
      );

      checkCancelBeforeDispatch({
        type: "INSERTED_DOC",
        payload: insertDocument,
      });
    } catch (error) {
      checkCancelBeforeDispatch({
        type: "ERROR",
        payload: error.message,
      });
    }
  };

  useEffect(() => {
    return () => setCancelled(true);
  }, []);

  return { insertDocument, response };
};
