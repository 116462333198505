//css
import { FooterContainer } from "./styles";

const Footer = () => {
  return (
    <FooterContainer>
      <p>Copyright @ 2025 Misenscene | Todos os direitos reservados |</p>
    </FooterContainer>
  );
};

export default Footer;
