import { SchoolsContainer, TheatersSection /* City */ } from "./styles";

import img from "../../images/teatroescola.png";
import { ParagraphPatern, TitleSectionsPatern } from "../../App";
import Contacts from "../../components/Contacts";
import InTheaters from "../../components/InTheaters";

import { useFetchDocuments } from "../../hooks/useFetchDocuments";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const enviroment = process.env.REACT_APP_ENVIROMENT;

const Schools = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { documents, loading, error } = useFetchDocuments(enviroment === "prod" ? "teatros" : "teatros-test", "2");
  return (
    <SchoolsContainer>
      <img src={img} alt="Teatro nas Escolas" />
      <TitleSectionsPatern>Descrição</TitleSectionsPatern>
      <ParagraphPatern>
        A fim de proporcionar o contato das crianças e jovens com a arte
        teatral, a Misenscene Arte e Cultura desenvolve o projeto Teatro nas
        Escola. Trabalhamos com peças voltadas desde a Educação Infantil até o
        Ensino Médio e levamos para a sua escola.
      </ParagraphPatern>
      <TheatersSection>
        {documents && !loading && !error && (
          <>
            {documents && documents.length <= 0 && <h2>Em Breve!</h2>}
            {documents &&
              documents.length > 0 &&
              documents.map((peca, i) => <InTheaters key={i} theater={peca} />)}
          </>
        )}
      </TheatersSection>
      <Contacts subject={"Teatro nas Escolas"} />
    </SchoolsContainer>
  );
};

export default Schools;
